.plans-container{
    padding: 0 2rem;
    display: flex;
    margin-top: -4rem;
    flex-direction: column;
    gap: 4rem;
    position: relative;
}
.plans{
    display: flex; 
    align-items: center;
    justify-content: center;
    gap: 3rem;
}
.plan{
    display: flex;
    flex-direction: column;
    background: var(--caloryCard);
    color: antiquewhite;
    gap: 2rem;
    padding :1.5rem;
    width: 15rem;
}
.plan:nth-child(2){
    transform: scale(1.2);
    background: var(--planCard);
}
.plan>svg{
        fill: rgb(233, 143, 143);
        width: 2rem;
        height: 2rem;
}
.plan>:nth-child(2){
    font-size: 1rem;
    font-weight: bold;
}
.plan>:nth-child(3){
    font-weight: bold;
    font-size: 3rem;
}
.plan>:nth-child(5) {
    font-size: 0.8rem;
}
.features{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.feature {
    display: flex;
align-items: center;
    gap: 1rem;
}
.feature>img{
    width: 1rem;
}
.plans>:nth-child(2)>svg{
    fill: white;
    
}
.plans>:nth-child(2)>button {
color: orange;
}
.plans-blur1 {
    width: 35rem;
    left: 0;
    height: 30rem;
    top: 8rem;
}
.plans-blur2 {
    width: 35rem;
    right: 0;
    height: 30rem;
    top: 8rem;
}
@media screen and (max-width:768px){
.plans{
    flex-direction: column;
}
.plan:nth-child(2){
    transform: none;
}
}