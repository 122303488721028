.footer-container{
    position: relative;
}
.footer-container>hr{
    border: 1px solid var(--lightgray);
}
.footer{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    justify-content: center;
    align-items: center;
    height: 20rem;
}
.socials{
    display: flex;
    gap: 4rem;
  
}
.socials>img{
    width: 2rem;
        height: 2rem;
        cursor: pointer;
}
.logo-f>img{
    width: 10rem;
}
.fblur1 {
    width: 40rem;
    height: 14rem;
    right: 15%;
    bottom: 0;
    filter: blur(160px);
    background: rgb(221, 128, 52);
}
.fblur2 {
    width: 40rem;
    height: 14rem;
    left: 15%;
    bottom: 0;
    filter: blur(160px);
    background: rgb(221, 128, 52);}